import Vue from "vue";
import VueRouter from "vue-router";
import rest from "@/utils/rest";
import VueCookie from "vue-cookie";
import NProgress from "nprogress";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Account.vue"),
  },
  // {
  //   path: "/account",
  //   name: "Account",
  //   component: () => import("../views/Account.vue"),
  // },
  {
    path: "/fileList",
    name: "FileList",
    component: () => import("../views/FileList.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/explain",
    name: "Explain",
    component: () => import("../views/Explain.vue"),
  },
  {
    path: "/fileDetail",
    name: "FileDetail",
    component: () => import("../views/FileDetail.vue"),
  },
  {
    path: "/modifyPwd",
    name: "ModifyPwd",
    component: () => import("../views/ModifyPwd.vue"),
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/Report.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (rest.getToken()) {
    if (to.path !== "/login") {
      if (VueCookie.get("roleType") === "SUPERADMIN") {
        if (to.path === "/fileList") {
          NProgress.done();
          next({ path: "/" });
        } else {
          NProgress.done();
          next();
        }
      } else {
        if (to.path === "/") {
          NProgress.done();
          next({ path: "/fileList" });
        } else {
          NProgress.done();
          next();
        }
      }
    } else {
      if (VueCookie.get("roleType") === "SUPERADMIN") {
        NProgress.done();
        next({ path: "/" });
      } else {
        NProgress.done();
        next({ path: "/fileList" });
      }
    }
  } else {
    if (to.path === "/login" || to.path === "/explain") {
      NProgress.done();
      next();
    } else {
      NProgress.done();
      next("/login");
    }
  }
});

export default router;
