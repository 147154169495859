import axios from "axios";
import VueCookie from "vue-cookie";
import { Message } from "element-ui";
import qs from "qs";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
// import router from "@/router";
axios.defaults.timeout = 60000;
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
// request拦截器
axios.interceptors.request.use((config) => {
  NProgress.start();
  if (VueCookie.get("token")) {
    const Token = VueCookie.get("token");
    config.headers = {
      "Content-Type": "application/json;charset=UTF-8",
      token: Token,
    };
  } else {
    config.headers = {
      "Content-Type": "application/json;charset=UTF-8",
    };
  }
  return config;
});

// respone拦截器
axios.interceptors.response.use(
  (res) => {
    NProgress.done();
    return res.data;
  },
  (err) => {
    NProgress.done();
    if (err.response.status === 401) {
      VueCookie.delete("token");
      window.location.href = "login";
      console.log("token过期");
    } else if (err.response.status === 403) {
      Message({
        showClose: true,
        message: "权限不够!",
        type: "error",
        duration: 3000,
      });
    } else {
      Message({
        showClose: true,
        message: err.response.data.message,
        type: "error",
        duration: 3000,
      });
    }

    return Promise.reject(err);
  }
);

class Rest {
  constructor() {
    this.accessToken = VueCookie.get("token");
  }

  setToken(token) {
    this.accessToken = token;
    VueCookie.set("token", token);
  }

  getToken() {
    VueCookie.get("token");
    return this.accessToken;
  }

  clearToken() {
    this.accessToken = null;
    VueCookie.delete("token");
  }

  logout() {
    this.clearToken();
    VueCookie.delete("roleType");
    VueCookie.delete("accountId");
    VueCookie.delete("username");
  }

  get(url, params) {
    return axios.get(url, {
      params: params,
    });
  }

  get2(url, params) {
    return axios.get(url, {
      params: params,
    });
  }

  post(url, params) {
    return axios.post(url, params);
  }

  // 把参数拼接到url上
  put(url, params) {
    return axios.put(url + "?" + qs.stringify(params));
  }

  delete(url, params) {
    return axios.delete(url + "?" + qs.stringify(params));
  }

  delete2(url) {
    return axios.delete(url);
  }
}

let rest = new Rest();
export default rest;
